import React, { useState } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import Axios from "axios";
import { API_URL, REF_APP } from "../../../config";
import Loader from "../../common/Loader";

const ForgotPassword = ({ setForgotPassword, captchaToken }) => {
  const [email, setEmail] = useState("");
  const [emailOk, setEmailOk] = useState(false);
  const [errors, setErrors] = useState({});
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  function checkEmail() {
    setCheckingEmail(true);
    Axios.post(API_URL + "auth/renew-password/" + REF_APP, {
      login: email,
      captchaToken,
    })
      .then((res) => {
        setCheckingEmail(false);
        setEmailSent(true);
      })
      .catch((err) => {
        console.log(err);
        setCheckingEmail(false);

        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      });
  }

  return (
    <div
      className="d-flex align-items-center justify-content-center flex-column  pt-5 animated fadeIn mx-auto"
      style={{ maxWidth: "300px", minWidth: "300px" }}
    >
      {emailSent ? (
        <div className=" text-left overflow-hidden">
          <div className="d-center flex-column justify-content-between">
            <img
              className="img-fluid mb-5"
              width="60"
              src="/images/logo-small.svg"
            />
            <img className="mb-3" src="/images/merlin.svg" />
          </div>
          <p className="font-weight-bold mb-3">
            Un email vous a été envoyé pour réinitialiser votre mot de passe.
          </p>
          <p>
            Ouvrez l’email puis cliquez sur le gros bouton bleu, vous serez
            connecté en un rien de temps !
          </p>
          <button
            onClick={() => setForgotPassword(false)}
            className="btn btn-link w-100 "
          >
            <i className="fa fa-chevron-left mr-2"></i>Revenir à la connexion
          </button>
        </div>
      ) : (
        <>
          <div className="mb-3 text-center">
            <img
              className="img-fluid"
              width="60"
              src="/images/logo-small.svg"
            />
            {/* <CanvasParticle
                  id="canvas2"
                  image64={logoPercevalSmall}
                  radius={30}
                /> */}
            <div
              className="mt-3 mb-3 text-success"
              style={{ fontSize: 20, fontWeight: "700" }}
            >
              Mot de passe oublié
            </div>
          </div>
          <div className=" py-4">
            <p className="mb-4">
              Un lien permettant de modifier votre mot de passe sera envoyé à
              l’adresse ci-dessous.
            </p>
            <Control
              label="Entrez votre email"
              value={email}
              change={(e) => setEmail(e.target.value)}
              name="email"
              error={{ ...errors, email: errors?.login }}
            />
            {checkingEmail ? (
              <Loader withContainer={true} />
            ) : (
              <>
                {captchaToken && (
                  <button
                    className="btn btn-primary w-100 animated fadeIn mt-3"
                    onClick={checkEmail}
                  >
                    Réinitialiser mon mot de passe
                  </button>
                )}
                <button
                  onClick={() => setForgotPassword(false)}
                  className="btn btn-link w-100 mt-3 "
                >
                  <i className="fa fa-chevron-left mr-2"></i>Revenir à la
                  connexion
                </button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
